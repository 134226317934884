import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { WHLoginDataService } from '@workheld/workheld-shared-lib';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private translateService: TranslateService,
    private injector: Injector,
    private loginDataService: WHLoginDataService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
    super();
  }

  handleError(error: Error) {
    super.handleError(error);
    const isLocalhost = window.location.hostname === 'localhost';
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      this.snackBar
        .open(
          this.translateService.instant('app.ui.somethingWentWrong.message'),
          this.translateService.instant('app.ui.refresh.label'),
        )
        .onAction()
        .subscribe(() => {
          window.location.reload();
        });
    }

    if (this.loginDataService.activeUserDOM$ && !isLocalhost) {
      const details = {
        'User ID': this.loginDataService.activeUserDOM$?.value?.id,
        User: this.loginDataService.activeUserDOM$?.value?.formattedName,
        Tenant: this.loginDataService.activeTenantDOM$?.value?.token,
      };

      Sentry.setContext('Workheld', details);
      this._handleSentryError(error);
    } else if (!isLocalhost) {
      this._handleSentryError(error);
    }

    const toastrService = this.injector.get(ToastrService);

    if (error instanceof HttpErrorResponse) {
      let errorMessage = '';
      const err: any = error.error ? error.error : error;
      const code = err.code ? err.code : 0;

      if (err?.code) {
        errorMessage = this.translateService.instant('ERROR.' + code);
        errorMessage = errorMessage.localeCompare('ERROR.' + code)
          ? this.translateService.instant('ERROR.' + code)
          : err.message;
        if (err.affectedFields) {
          errorMessage = errorMessage.replace(/{{\w+}}/, err.affectedFields[0]);
        }
      } else if (err.message) {
        errorMessage = err.message;
      } else {
        errorMessage = `${error.message}`;
      }

      toastrService.error(errorMessage, '' + error.status);
    }
  }

  private _handleSentryError(error: any): void {
    if (error.message) {
      Sentry.captureException(new Error(error.message));
    } else if (typeof error === 'object') {
      Sentry.captureException(new Error(JSON.stringify(error)));
    } else {
      Sentry.captureException(new Error(error));
    }
  }
}
