// ANGULAR
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
  inject,
  ViewChild,
  AfterViewInit,
  effect,
  Injector,
  signal,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// ANGULAR MATERIAL
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// RxJS 6
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';

// SERVICES
import {
  IWHFlowMetadataTranslationModel,
  IWHMaterialEndpointConfigModel,
  WHFeatureKeyENUM,
  WHLoginDataService,
  WHMetadataDataService,
  WHNgxToastrENUM,
  WHNgxToastrService,
  WHWorkStepDOM,
} from '@workheld/workheld-shared-lib';
import { toObservable } from '@angular/core/rxjs-interop';

import {
  INVENTORY_TYPE,
  IWHPageableMaterialDTO,
  MaterialAssignment,
  MaterialAsyncService,
  MaterialDTO,
  MaterialsReservationPayload,
  MaterialsToWorkStepPayload,
  WorkstepMaterialList,
} from 'src/app/app-services-async/material-async.service';
import { MatTableDataSource } from '@angular/material/table';
import { isFuture } from 'date-fns';
import { MatDialogRef } from '@angular/material/dialog';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';

interface AssignWorkStepToMaterialsCommand {
  assignments: {
    assignmentDate: string;
    workStepId: string;
    materialId: string;
    unit: string;
    quantity: number;
  }[];
}

@Component({
  selector: 'w-h-material-preview',
  templateUrl: './w-h-material-preview.component.html',
  styleUrls: ['./w-h-material-preview.component.scss'],
})
export class WHMaterialPreviewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() public endpointConfig: IWHMaterialEndpointConfigModel;
  @Input({ transform: isWorkstepDateValid }) workstep;
  dataSource = new MatTableDataSource<MaterialDTO>([]);

  INVENTORY_TYPE_TRANSLATIONS = INVENTORY_TYPE;

  RESERVATION_STATUS = {
    CREATED: 'app.ui.status.created',
    FULFILLED: 'app.ui.status.fullfilled',
    COMPLETED: 'app.ui.status.completed',
    DELETED: 'app.ui.status.deleted',
    INVENTORY_MANAGED: 'app.ui.status.reservable',
    NON_INVENTORY_MANAGED: 'app.ui.status.notReservable',
  };
  materialList$: Observable<any> = new Observable();
  pageableMaterialDTO: IWHPageableMaterialDTO = null;
  materialDropdownLoading$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );
  disableAddNewMaterial = true;
  selectedMaterial: MaterialDTO;

  displayedColumns = [
    'itemPositionNumber',
    'materialTitle',
    'inventoryType',
    'quantity',
    'unit',
    'additionalInformation',
    'materialStatus',
    'actions',
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  currentLocale = 'en';
  UNIT;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        if (property === 'materialTitle') {
          return this.cleanData(item.materialTitle);
        } else {
          return item[property] || '';
        }
      };
      this.dataSource.sort = sort;
    }
  }
  sort;

  @Output()
  public payloadEmmiter: EventEmitter<AssignWorkStepToMaterialsCommand> =
    new EventEmitter(false);

  // MANAGE SUBSCRIPTIONS
  private subscription = new Subscription();
  isReserveFlagEnabled = inject(WHLoginDataService).featureConfigMap$.value.get(
    WHFeatureKeyENUM.MATERIAL_RESERVATION,
  );
  isMaterialAvailabilityFlagEnabled = inject(
    WHLoginDataService,
  ).featureConfigMap$.value.get(WHFeatureKeyENUM.MATERIAL_AVAILABILITY);

  materialsDataTable: MaterialDTO[] = [];
  assignments = {
    newAssignments: new Map<string, MaterialDTO>(),
    deletedAssignments: new Map<string, MaterialDTO>(),
  };
  materialsTableLoading$: BehaviorSubject<boolean>;
  isReservationMade: boolean;
  reservationNumber: string;
  reservationId: string;
  isReserveButtonEnabled = signal(false);
  isSaveButtonEnabled = signal(false);
  private injector = inject(Injector);

  constructor(
    private materialsService: MaterialAsyncService,
    private matDialogRef: MatDialogRef<WHMaterialPreviewComponent>,
    private formReferenceService: FormReferenceService,
    private translateService: TranslateService,
    private ngxToastrService: WHNgxToastrService,
    private metaDataService: WHMetadataDataService,
  ) {
    effect(() => {
      this.materialDropdownLoading$.next(
        this.materialsService.fetchingMaterials(),
      );
    });
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.translateService.onLangChange.pipe(startWith('')).subscribe(() => {
        this.currentLocale = this.translateService.currentLang;
      }),
    );

    this.subscription.add(
      this.metaDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.UNIT = metadataTranslation.UNIT;
        },
      ),
    );

    if (this.isReserveFlagEnabled) {
      this.displayedColumns.splice(5, 0, 'reservationStatus');
    }

    if (this.isMaterialAvailabilityFlagEnabled) {
      this.displayedColumns.splice(5, 0, 'availability');
    }

    const { data$, loading$ } =
      this.materialsService.workStepMaterialListEntity;
    this.materialsTableLoading$ = loading$;

    this.subscription.add(
      data$
        .pipe(
          filter((materialsTable) => !!materialsTable),
          map((materialsTable: WorkstepMaterialList) => {
            this.materialsDataTable = materialsTable.reservationListDetails;
            this.isReservationMade = !!materialsTable.reservationId;
            this.reservationId = materialsTable.reservationId;
            this.reservationNumber = materialsTable.reservationNumber;
            this._setDataTable(materialsTable.reservationListDetails);
          }),
        )
        .subscribe(),
    );
  }

  public ngOnInit(): void {
    if (!this.endpointConfig.apiUrl) {
      return;
    }

    toObservable(this.materialsService.materialPageResultDTO, {
      injector: this.injector,
    }).subscribe((materials: IWHPageableMaterialDTO) => {
      const override = materials?.content.map(
        (material: MaterialDTO & { disabled; inventoryTypeKey }) => {
          material.inventoryTypeKey = this.translateService.instant(
            this.INVENTORY_TYPE_TRANSLATIONS[material.inventoryType],
          );
          material.disabled = this.materialsDataTable.some(
            (materialTable) =>
              materialTable.materialId === material.id &&
              !materialTable.deleted,
          );
          return material;
        },
      );
      this.pageableMaterialDTO = materials;
      this.materialList$ = of({ ...materials, content: override || [] });
    });

    this.subscription.add(
      this.materialsService
        .getMaterialsByWorkStepId(this.endpointConfig.workStepId)
        .subscribe(),
    );
  }

  handleScrollEvent(event: { term: string; page: number }) {
    this.materialsService.getMaterials({
      page: event.page,
      searchTerm: event.term,
      sort: 'title,asc',
    });
  }

  handleMaterialSelect(materialDOM: MaterialDTO) {
    if (!materialDOM) {
      this.selectedMaterial = null;
      this.disableAddNewMaterial = true;
      return;
    }
    this.disableAddNewMaterial = this.materialsDataTable.some(
      (m) => m.materialId === materialDOM.id && !m.deleted,
    );
    if (!this.disableAddNewMaterial) {
      this.selectedMaterial = materialDOM;
    }
  }

  public assignMaterialToWS() {
    const newMaterial = {
      materialExtId: this.selectedMaterial.extId,
      materialId: this.selectedMaterial.id,
      materialTitle: this.selectedMaterial.title,
      materialUnit: this.selectedMaterial.unit,
      inventoryType: this.selectedMaterial.inventoryType,
      materialStatus: this.selectedMaterial.status,
      quantity: 1,
      reservationStatus: '',
      additionalInformation: '',
      deleted: false,
    } as MaterialDTO;
    this.assignments.newAssignments.set(newMaterial.materialId, newMaterial);
    this.materialsDataTable.unshift(newMaterial);
    this._setDataTable(this.materialsDataTable);

    const override = this.pageableMaterialDTO?.content.map(
      (material: MaterialDTO & { disabled }) => {
        material.disabled = this.materialsDataTable.some(
          (materialTable) =>
            materialTable.materialId === material.id && !materialTable.deleted,
        );
        return material;
      },
    );
    this.materialList$ = of({
      ...this.pageableMaterialDTO,
      content: override || [],
    });
    this.disableAddNewMaterial = true;
  }

  public handleDelete(materialDOM: MaterialDTO) {
    const material = this.assignments.newAssignments.get(
      materialDOM.materialId,
    );

    if (material) {
      this.assignments.newAssignments.delete(materialDOM.materialId);
      this.materialsDataTable = this.materialsDataTable.filter(
        (m) => m.materialId !== materialDOM.materialId,
      );
      this.dataSource.data = this.materialsDataTable;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else if (
      this.assignments.deletedAssignments.has(materialDOM.materialId)
    ) {
      this.assignments.deletedAssignments.delete(materialDOM.materialId);
    } else {
      // if material is already saved
      this.assignments.deletedAssignments.set(
        materialDOM.materialId,
        materialDOM,
      );
    }

    this._checkActionButtonsState();
  }

  private _setDataTable(data: MaterialDTO[]) {
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this._checkActionButtonsState();
  }

  public onInputChange(materialDOM: MaterialDTO) {
    const matchingMaterials = this.materialsDataTable.filter(
      (material) => material.materialId === materialDOM.materialId,
    );
    const material =
      matchingMaterials.length > 1
        ? matchingMaterials.find(
            (material) => material.reservationStatus !== 'DELETED',
          )
        : matchingMaterials[0];

    if (material) {
      material.quantity = materialDOM.quantity;
      material.additionalInformation = materialDOM.additionalInformation;
      this.assignments.newAssignments.set(material.materialId, material);
    }

    this._checkActionButtonsState();
  }

  onSubmit(type: 'SAVE' | 'RESERVATION') {
    const materials2WSpayload: MaterialsToWorkStepPayload = {
      workStepId: this.endpointConfig.workStepId,
      assignments: [],
      removedAssignments: [],
    };

    for (let material of this.assignments.newAssignments.values()) {
      materials2WSpayload.assignments.push({
        id: material.workStep2MaterialId,
        workStep2MaterialId: material.workStep2MaterialId,
        workStepId: this.endpointConfig.workStepId,
        materialId: material.materialId,
        quantity: material.quantity,
        additionalInformation: material.additionalInformation,
      });
    }

    for (let material of this.assignments.deletedAssignments.values()) {
      materials2WSpayload.removedAssignments.push(material.workStep2MaterialId);
    }

    this.subscription.add(
      this.materialsService
        .assignMaterialsToWorkStep(materials2WSpayload)
        .pipe(
          switchMap((materials: WorkstepMaterialList) => {
            this._setDataTable(materials?.reservationListDetails);

            if (type === 'RESERVATION') {
              const reservationPayload: MaterialsReservationPayload = {
                workStepId: this.endpointConfig.workStepId,
                reservationTargetDate: new Date(
                  this.workstep.startDate,
                ).toISOString(),
                reservationItems: [],
              };

              if (this.isReservationMade) {
                reservationPayload.id = this.reservationId;
              }

              reservationPayload.reservationItems = this.materialsDataTable
                .filter(
                  (material) =>
                    !material.reservationItemId &&
                    material.inventoryType === 'INVENTORY_MANAGED',
                )
                .map((material) => {
                  return {
                    workStepId: this.endpointConfig.workStepId,
                    materialId: material.materialId,
                    quantity: material.quantity,
                    additionalInfo: material.additionalInformation,
                  } as MaterialAssignment;
                });

              for (let material of this.assignments.newAssignments.values()) {
                // reserve only inventory managed materials
                if (
                  material.inventoryType === 'INVENTORY_MANAGED' &&
                  material.materialStatus === 'ACTIVE' &&
                  !reservationPayload.reservationItems.some(
                    (m) => m.materialId === material.materialId,
                  )
                ) {
                  reservationPayload.reservationItems.push({
                    id: material.reservationItemId,
                    workStepId: this.endpointConfig.workStepId,
                    materialId: material.materialId,
                    quantity: material.quantity,
                    additionalInfo: material.additionalInformation,
                  });
                }
              }

              for (let material of this.assignments.deletedAssignments.values()) {
                if (
                  material.inventoryType === 'INVENTORY_MANAGED' &&
                  material.reservationItemId
                ) {
                  reservationPayload.reservationItems.push({
                    id: material.reservationItemId,
                    workStepId: this.endpointConfig.workStepId,
                    materialId: material.materialId,
                    quantity: material.quantity,
                    additionalInfo: material.additionalInformation,
                    deleted: true,
                  });
                }
              }

              return this.materialsService.makeReservation(reservationPayload);
            } else {
              return of(materials);
            }
          }),
        )
        .subscribe(async (materials: any) => {
          const materialAssigned = await this._checkIfMatterialsAssigned();

          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey:
              'workstepmaterials.ui.assignsuccess.notification',
          });

          if (materials) {
            this._setDataTable(
              materials.reservationListDetails || materials.reservationItems,
            );
          }
          this.assignments.deletedAssignments.clear();
          this.assignments.newAssignments.clear();

          this.matDialogRef.close(materialAssigned);
        }),
    );
  }

  async handleClose() {
    const materialAssigned = await this._checkIfMatterialsAssigned();

    this.formReferenceService.unsavedChanges =
      this.assignments.newAssignments.size > 0 ||
      this.assignments.deletedAssignments.size > 0;

    if (this.formReferenceService.isDirty) {
      const ref = this.formReferenceService.createDialog();
      ref
        .pipe(
          tap((canDiscard) => {
            if (canDiscard) {
              this.formReferenceService.unsavedChanges = false;

              this.matDialogRef.close(materialAssigned);
            }
          }),
        )
        .subscribe();
    } else {
      this.matDialogRef.close(materialAssigned);
    }
  }

  isInRemoveAssignments(material) {
    return this.assignments.deletedAssignments.has(material.materialId);
  }

  private _checkIfMatterialsAssigned() {
    return new Promise((resolve) => {
      this.subscription.add(
        this.materialsService
          .isMaterialAssigned(this.workstep.id)
          .subscribe((res) => resolve(res)),
      );
    });
  }

  private _checkActionButtonsState() {
    // check if reserve button should be enabled
    // if there are new assignments or deleted assignments
    // and if there are any inventory managed materials
    const hasNewAssignments = this.assignments.newAssignments.size > 0;
    const hasDeletedAssignments = this.assignments.deletedAssignments.size > 0;
    const hasNewInventoryManagedMaterials = Array.from(
      this.assignments.newAssignments.values(),
    ).some(
      (material) =>
        material.inventoryType === 'INVENTORY_MANAGED' &&
        !this.isInRemoveAssignments(material),
    );

    const hasDeletedInventoryManagedMaterials = Array.from(
      this.assignments.deletedAssignments.values(),
    ).some(
      (material) =>
        material.inventoryType === 'INVENTORY_MANAGED' &&
        material.reservationItemId,
    );
    const hasUnsavedInventoryManagedMaterials = this.materialsDataTable.some(
      (material) =>
        material.inventoryType === 'INVENTORY_MANAGED' &&
        !material.reservationItemId &&
        !this.isInRemoveAssignments(material),
    );

    this.isReserveButtonEnabled.set(
      ((hasNewAssignments || hasDeletedAssignments) &&
        (hasNewInventoryManagedMaterials ||
          hasDeletedInventoryManagedMaterials)) ||
        hasUnsavedInventoryManagedMaterials,
    );

    // if reservation is made it should be disabled
    this.isSaveButtonEnabled.set(
      ((hasNewAssignments || hasDeletedAssignments) &&
        !this.isReservationMade) ||
        ((hasNewAssignments || hasDeletedAssignments) &&
          !hasNewInventoryManagedMaterials &&
          !hasDeletedInventoryManagedMaterials),
    );
  }

  private cleanData(sortString: string): string {
    let cleanString = '';
    if (sortString) {
      cleanString = sortString.trim();
    }
    return cleanString.toLowerCase();
  }

  public ngOnDestroy(): void {
    this.materialsService.workStepMaterialListEntity.data$.next(null);
    this.materialsService.materialPageResultDTO.set(null);
    this.subscription.unsubscribe();
  }
}

function isWorkstepDateValid(workstep: WHWorkStepDOM) {
  return {
    ...workstep,
    isDateValid: workstep.startDate && isFuture(new Date(workstep.startDate)),
  };
}
